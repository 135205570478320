import { useState, useContext } from 'react'
import { Button } from 'react-bootstrap';
// import './AddItem.css'
import BtnPostAdd from './BtnPostAdd'
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import { TiShoppingCart } from "react-icons/ti";


const AddItem = ({ producto, enVistaProducto }) => {

    const { addToCart, cartLocal } = useContext(cartContext)
    const [contador, setContador] = useState(1)
    const { grid, handleDisplayList } = useContext(globalContext);

    console.log("esta en la ficha producto?", enVistaProducto);



    const sumar = (event) => {
        event.preventDefault();
        if (contador < producto.Stock) {
            setContador(contador + 1)
        }
        else {
            return false
        }
    }
    const restar = (event) => {
        event.preventDefault(); // Asegúrate de evitar el comportamiento predeterminado.
        if (contador > 1) {
            setContador(contador - 1)
        }
        else {
            return false
        }
    }

    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const onAdd = (event) => {
        event.preventDefault(); // Asegúrate de evitar el comportamiento predeterminado.

        if (!itemInCart || itemInCart.length === 0) {
            addToCart(producto, contador)
        }
        else if (producto.Stock - itemInCart.contador - contador >= 0) {
            addToCart(producto, contador)
        }
        else {
            //   SwalFire('Error!', 'El stock actual es insuficiente para agregar más productos', 'error')
            toast.error(t("producto.stock-insuficiente"), {
                autoClose: 400
            });
        }
    }
    const [t, i18next] = useTranslation("global")

    const buttonsList = {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1rem',
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        transition: 'background-color 0.2s ease-in-out',
        // marginLeft: '5px', /* Adjust the spacing between buttons */
    }
    const buttonsGrid = {
        backgroundColor: 'transparent',
        height: "40px",
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        transition: 'background-color 0.2s ease-in-out',
        border: "2px solid #000"
        // marginLeft: '5px', /* Adjust the spacing between buttons */
    }

    if (itemInCart) return <BtnPostAdd producto={producto} />

    else {

        return (
            <div className={`${grid && 'flexGridAddCartAddItem'} ${!grid && 'd-flex justify-content-around flex-row-reverse gap-3'}`}>
                <div className="counter" style={grid ? buttonsGrid : buttonsList}>


                    <span style={{
                        fontSize: '1.5rem',
                        margin: '0 10px', /* Adjust the spacing around the counter value */
                    }} className="counter-value">{contador}</span>

                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "inherit", borderLeft: "2px solid #000" }}>
                        <button className="counter-button plus" onClick={sumar} style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '2rem',
                            width: '35px',
                            height: '35px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000',
                            transition: 'background-color 0.2s ease-in-out',
                            // marginLeft: '5px', /* Adjust the spacing between buttons */
                        }}>
                            {/* grid ? '+'
                                : */ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                                </svg>
                            }
                        </button>
                        <button className="counter-button minus mesita" onClick={restar} style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '2rem',
                            width: '35px',
                            height: '35px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000',
                            transition: 'background-color 0.2s ease-in-out',
                            // marginRight: '5px', /* Adjust the spacing between buttons */
                        }}>
                            {/* grid ? '-'
                                : */ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            }
                        </button>
                    </div>
                </div>
                {/* <div className='bntAddMinus' id='divAddToCart'>
                    <button onClick={restar} className='restar'><i className={`fa-sharp fa-solid fa-minus ${grid && 'ml-0'}`}></i></button>
                    <input value={contador} readOnly />
                    <button onClick={sumar} className='sumar'><i className="fa-solid fa-plus"></i></button>
                </div> */}


                {
                    grid ?
                        <div onClick={onAdd} style={{ fontSize: "36px", cursor: "pointer", border: "2px solid #000", display: "flex", alignItems: "center" }} className='iconAgregarCarrito'>
                            <TiShoppingCart />
                        </div>
                        :
                        <button onClick={onAdd} className='agregarCarrito'>{t("producto.agregar-carrito")}</button>
                }
            </div>
        )
    }
}
export default AddItem